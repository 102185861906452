import { useState } from 'react';
import { BaseButton, BaseInput } from 'components/elements';
import { BaseLayout, BasePageHeader } from 'components/layouts';
import { HOWCALL, NICKNAME, LESSTEN, CANUSENICKNAME, ALREADYUSENICKNAME,
        JOIN, CONGRATULATIONJOIN, VALIDNICKNAME, NEXT} from 'constants';
import { postMemberNicknameAPI } from 'api/member';
import { Loading } from 'components/containers';
import { useErrorHandler } from 'hooks/useErrorHandler';
import { AGENT } from 'constants/Mobile';

export const JoinSettingNick = ({ onMoveStep, userIdx, common }) => {

  const AGENT_TYPE = common.agentType;

  const [valid, setValid] = useState(false);
  const [reason, setReason] = useState('');
  const [nicknameText, setNicknameText] = useState('');
  const [isShow, setShow] = useState({ 
    loading: false,
    showBottom: true 
  });
  const [errorData, setErrorData] = useState(null);
  const { ErrorModal } = useErrorHandler({ errorData });


  // 로딩 토글
  const fnToggleLoading = (value) => {
		setShow({ ...isShow, loading: value })
	} 

  const fnHideBottom = () => {
    if (AGENT_TYPE === AGENT.IOS_APP) {
      setShow({ ...isShow, showBottom: false })
    }
  }
  const fnShowBottom = () => {
    if (AGENT_TYPE === AGENT.IOS_APP) {
      setShow({ ...isShow, showBottom: true })
    }
  }

  const fnValidationCheck = (value) => {
    const check = /^[0-9a-zA-Z가-힣ㆍᆞᆢㄱ-ㅎㅏ-ㅣ\x20]*$/;
    if (value && !check.test(value)) {
      return false;
    }
    if (value?.length > 10 || !value) {
      setReason(VALIDNICKNAME);
      setValid(false);
      setNicknameText(value);
      return false;
    } else {
      setReason(null);
      setValid(true);
      setNicknameText(value);
    }
  };

  const fnSaveNickName = async () => {
    const data = {
      user_idx: userIdx,
      user_nickname: nicknameText
    }
    fnToggleLoading(true);
    await postMemberNicknameAPI({data})
    .then(res => {
      // console.log(res);
      if(res?.data?.responseCode === 400) {
        setReason(ALREADYUSENICKNAME);
        setValid(false);
      } else if (res?.data?.responseCode === 200) onMoveStep();
    })
    .catch(err => {
      console.error(err);
      setErrorData(err);
    })
    .finally(() => {
      fnToggleLoading(false);
    })
  }

  return (
    <>
    <BaseLayout header={false} footer={false}>
      <BasePageHeader 
        title={JOIN}
        pageType={'join'}
      />
      <div className="wrap wrap-join">
        <div className="member">
          <div className="join">
            <div className="step-2">
              <h2 className="title-l">
                {CONGRATULATIONJOIN}
                <br />
                {HOWCALL}
              </h2>
              <div className="content">
                <div className="join-input">
                  <strong>{NICKNAME}</strong>
                  <div className={`${valid ? 'success' : 'fail'}-input`}>
                    <BaseInput
                      placeholder={LESSTEN}
                      value={nicknameText}
                      onChange={(value) => {
                        fnValidationCheck(value);
                      }}
                      maxLength={10}
                      onBlur={fnShowBottom}
                      onFocus={fnHideBottom}
                    />
                    {valid ? (
                      <span className="success">
                        {CANUSENICKNAME}
                      </span>
                    ) : (
                      <span className="fail">{reason}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
          <div className="button-wrap">
          { isShow.showBottom &&
            <BaseButton label={NEXT} disabled={!valid} onClick={fnSaveNickName} />
          }
          </div>
      </div>

      {/* 에러 */}
      {ErrorModal}
      </BaseLayout>

      {/* 로딩바 */}
      <Loading loading={isShow?.loading}/>
    </>
  );
};
