import { useState } from 'react';
import { BaseButton, BaseConfirmButton, BaseInput } from 'components/elements';
import { ConfirmModal, Loading } from 'components/containers';
import { BaseLayout, BasePageHeader } from 'components/layouts';
import { JOIN, SELECT, RECOMMENDCODE, PLEASEINPUT, NOCODECANUSE, THERECOMMENDCODE, EXACTLYINPUTCODE, NEXT, PLEASEINPUTONEMORETIME, WRONGCODE, BACK, JUMP } from 'constants';
import { postMemberRecommenderAPI } from 'api/join';
import { useErrorHandler } from 'hooks/useErrorHandler';
import { AGENT } from 'constants/Mobile';

export const JoinSettingRecommend = ({ onMoveStep, userIdx, common }) => {

  const AGENT_TYPE = common.agentType;

  const [inputVal, setInputVal] = useState('');
  const [openModal, setOpenModal] = useState({ isModalPop: false });
  const [isShow, setShow] = useState({ 
    loading: false,
    showBottom: true

  });
  const [errorData, setErrorData] = useState(null);
  const { ErrorModal } = useErrorHandler({ errorData });


  // 로딩 토글
  const fnToggleLoading = (value) => {
    setShow({ ...isShow, loading: value })
  } 

  const fnHideBottom = () => {
    if (AGENT_TYPE === AGENT.IOS_APP) {
      setShow({ ...isShow, showBottom: false })
    }
  }
  
  const fnShowBottom = () => {
    if (AGENT_TYPE === AGENT.IOS_APP) {
      setShow({ ...isShow, showBottom: true })
    }
  }

  const fnMoveToNextStep = () => {
    setShow({ isModalPop: false });
    onMoveStep();
  }

  const fnValidationCheck = async () => {
    if (inputVal.length < 1) onMoveStep();
    else {
      const data = {
        user_idx: userIdx,
        recommend_code: inputVal
      }
      fnToggleLoading(true);
      await postMemberRecommenderAPI({data})
      .then(res => {
        if (res?.data?.responseCode === 400) setOpenModal({isModalPop: true});
        else if (res?.data?.responseCode === 200) onMoveStep();
      })
      .catch(err => {
        console.error(err);
        setErrorData(err);
      })
      .finally(() => {
        fnToggleLoading(false);
      })
    }
  };

  return (
    <>
    <BaseLayout header={false} footer={false}>
      <BasePageHeader 
        title={JOIN}
        pageType={'join'}
      />
      <div className="wrap wrap-join">
        <div className="member">
          <div className="join">
            <div className="step-5">
              <h2 className="title-l">
                {THERECOMMENDCODE}<br />{PLEASEINPUT} 
                <span className="point-g">({SELECT})</span>
              </h2>
              <div className="reference">
                {inputVal && (
                  <p className="reference-s">{NOCODECANUSE}</p>
                )}
              </div>
              <div className="content margin-top-139">
                <div className="join-input">
                  <strong>{RECOMMENDCODE}</strong>
                  <div className={`${inputVal && 'success'}-input`}>
                    <BaseInput 
                      placeholder={EXACTLYINPUTCODE}
                      value={inputVal}
                      onChange={(e) => setInputVal(e)}
                      onBlur={fnShowBottom}
                      onFocus={fnHideBottom}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
          <div className="button-wrap">
          { isShow.showBottom &&
            <BaseButton
              label={NEXT}
              onClick={fnValidationCheck}
            />
          }
          </div>
      </div>
      {/* 모달 */}
      <ConfirmModal
        show={openModal.isModalPop}
        label={WRONGCODE}
        subtitle={PLEASEINPUTONEMORETIME}
        footer={
          <>
            <BaseConfirmButton
              leftClassName={'point-r'}
              rightClassName={'point-g'} 
              leftLabel={JUMP}
              rightLabel={BACK}
              leftOnClick={fnMoveToNextStep}
              rightOnClick={() => {
                setOpenModal({ isModalPop: false });
              }}
            />
          </>
        }
      />

      {/* 에러 */}
      {ErrorModal}
      </BaseLayout>

      {/* 로딩바 */}
      <Loading loading={isShow?.loading}/>
    </>
  );
};
