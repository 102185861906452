import client from '../axios';
import { GET_COMEPETHOME_SELECT_LIST, GET_MAIN_LIST, GET_MONTHLY_LIST, GET_POPULAR_LIST, GET_TODAY_LIST } from './endpoint';

export const getMainListAPI = () => {
  return client.get(GET_MAIN_LIST);
}

export const getPopularListAPI = () => {
  return client.get(GET_POPULAR_LIST);
}

export const getComePetHomeSelectListAPI = () => {
  return client.get(GET_COMEPETHOME_SELECT_LIST);
}

export const getMonthlyListAPI = () => {
  return client.get(GET_MONTHLY_LIST);
}

export const getTodayListAPI = ({ params }) => {
  return client.get(GET_TODAY_LIST, { params });
}
