import { AlertModal } from "components/containers";
import { BaseTextButton } from "components/elements";
import { CHECK } from "constants";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import { URL_LOGIN } from "routes/RouteUrl";

export const useErrorHandler = ({ errorData, fileErrorData=null, okCallback=null }) => {

  const { logout } = useAuth();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    label: '',
    subtitle: '',
  }) 

  useEffect(() => {
    if (errorData) { // api 에러
      let label = '앗! 요청에 실패했어요..'
      let subtitle = '잠시후 다시 시도해주세요.'
      const errorMessage = errorData?.code;
      if (errorMessage) {
        if (errorMessage === "ERR_NETWORK") {
          // label = '앗! 문제가 발생했어요!'
          // subtitle = '잠시후 다시 시도해주세요.'
          label = '앗! 접근 권한이 없어요.'
          subtitle = '다시 로그인 해주세요.'
        } 
        setShow(true)
        setModalInfo({
          label,
          subtitle
        })
      }
      const errorCode = errorData?.response?.status;
      if (errorCode) {
        if (errorCode === 500) {
          label = '앗! 문제가 발생했어요.'
          subtitle = '잠시후 다시 시도해주세요.'
        } else if (errorCode === 400) {
          label = '앗! 요청에 실패했어요.'
          subtitle = '잠시후 다시 시도해주세요.'
        } else if (errorCode === 401) {
          label = '앗! 접근 권한이 없어요.'
          subtitle = '다시 로그인 해주세요.'
          logout();
          navigate(URL_LOGIN, {replace: true});
        } else if (errorCode === 405) {
          label = '앗! 요청에 실패했어요.'
          subtitle = '증상이 계속 될 경우 관리자에게 문의해주세요.'
        }
        setShow(true)
        setModalInfo({
          label,
          subtitle
        })
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorData])

  useEffect(() => {
    if (fileErrorData) { // 파일 에러
      const errorCode = fileErrorData?.type;
      const max = fileErrorData?.max;
      if (errorCode) {
        let label = '앗! 요청에 실패했어요..'
        let subtitle = '잠시후 다시 시도해주세요.'
        if (errorCode === 400) {
          label = '앗! 첨부 갯수를 초과했어요.'
          subtitle = `최대 ${max}장만 첨부 가능해요.`
        }
        setShow(true)
        setModalInfo({
          label,
          subtitle
        })
      }
    }
  }, [fileErrorData])

  function toggle() {
    setShow(!show);
    const errorMessage = errorData?.code;
    if (errorMessage) {
      if (errorMessage === "ERR_NETWORK") {
        //401일때 ERR_NETWORK로 에러 떨어짐 일단 임시 처리..
        //window.location.reload()
        logout();
        navigate(URL_LOGIN, {replace: true});
      }
    }
    const errorCode = errorData?.response?.status;
    if (errorCode) {
      if (errorCode === 401) {
        logout();
        navigate(URL_LOGIN, {replace: true});
      }
    }
    if (okCallback) okCallback()
  }


  return {
    ErrorModal: show && <AlertModal 
      show={show}
      label={modalInfo?.label}
      subtitle={modalInfo?.subtitle}
      footer={<BaseTextButton className="point-g" label={CHECK} onClick={toggle}/>}
    />,
    show,
    open: () => setShow(true),
    close: () => setShow(false),
    toggle,
  };

}