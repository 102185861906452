import { BaseButton } from "components/elements"
import { AGENT, ANDROID_APP, ANDROID_APP_LINK, IOS_APP, IOS_APP_LINK } from "constants/Mobile"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { URL_COMMUNITY, URL_DIARY, URL_HOME, URL_MORE_FAQ, URL_WALLET } from "routes/RouteUrl"
import { forwardLink } from "utils/utils"

export default function LinkWebApp({ common }) {

  const navigate = useNavigate();
  const [windowState, setWindowState] = useState(null);
  const AGENT_TYPE = common.agentType;

  const callApp = (defaultLink, linkUrl, notInstalledCallback) => {
    
    const link = forwardLink({
      url: window.location.href,
      origin: window.location.origin,
      pathname: window.location.pathname
    })
    let appUrl = defaultLink
    if (link.indexOf(URL_COMMUNITY) !== -1) {
      // 예시 /community/게시글번호
      const idx = link.split('/')[2]
      appUrl = `${linkUrl}?community=${idx}`
    } else if (link.indexOf(URL_DIARY) !== -1) {
      const date = link.split('?')[1].split('date=')[1]
      appUrl = `${linkUrl}?diary=${date}`
    } else if (link.indexOf(URL_WALLET) !== -1) {
      const date = link.split('?')[1].split('date=')[1]
      appUrl = `${linkUrl}?wallet=${date}`
    } else if (link.indexOf(URL_MORE_FAQ) !== -1) {
      const idx = link.split('/')[4]
      appUrl = `${linkUrl}?faq=${idx}`
    }
    window.location.href = appUrl
    //window.location.href = appUrl;
    // const timer = setInterval(() => {
    //   alert(window.document)
    //   if(document.webkitHidden || document.hidden){
    //     alert('설치')
    //     clearInterval(timer)
    //   } else {
    //     //alert('미설치')
    //   }
    // }, 1000);
    // setTimeout(() => {
    //   window.location.href = appUrl;
    //   // if(windowState === false) notInstalledCallback()
    // //   window.location.href = urlScheme;
    // //   // alert(windowState)
    // //   // if (windowState === 'focus') {
    // //   //   // 앱이 설치되어 있지 않은 상태
    // //   //   notInstalledCallback();
    // //   // } 
    // }, 100);

    // setTimeout(() => {
    //   alert(1)
    // }, 300)
  }

  useEffect(() => {
    const fnHandlePopup = () => {
      setWindowState(true)
    }
    window.addEventListener('popstate', fnHandlePopup);
  }, [])

  
  useEffect(() => {
    const useragent = navigator.userAgent;
    if(AGENT_TYPE === AGENT.ANDROID_WEB) {
      callApp(ANDROID_APP, ANDROID_APP_LINK, () => {
        // alert('app 미설치')
      })
    } else if (AGENT_TYPE === AGENT.IOS_WEB) {
      callApp(IOS_APP, IOS_APP_LINK, () => {

      })
    } else if ((useragent.match(/KAKAO/i) !== -1 || useragent.match(/NAVER/i) !== -1)
      && (useragent.match(/KAKAO/i) !== null || useragent.match(/NAVER/i) !== null)
      ) {
      // 인앱브라우저 예외처리 (카카오, 네이버)
      if (useragent.match(/iPhone/i)) {
        callApp(IOS_APP, IOS_APP_LINK, () => {

        })
      } else if (useragent.match(/Android/i)) {
        callApp(ANDROID_APP, ANDROID_APP_LINK, () => {
          // alert('app 미설치')
        })
      }
    } else { // PC .. 포워딩
      const link = forwardLink({
        url: window.location.href,
        origin: window.location.origin,
        pathname: window.location.pathname
      })
      navigate(link)
    }
  }, [])

  return (
    <main>
      <div className="wrap splash">
        <div>
          <img src="/assets/images/img_splash.svg" alt="스플래쉬 이미지" style={{ height: '600px' }}/>
        </div>
      </div>
      <BaseButton label={`컴펫홈 홈으로 이동`} onClick={() => { navigate(URL_HOME) }}></BaseButton>
    </main> 
  )
}